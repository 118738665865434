import React, { memo } from 'react';

import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

import { BodyText } from '@/components/text';
import Modal from '../Modal';

const ModalDesc = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '2rem',

  width: '30vw',
  minWidth: '20rem',
  padding: '2rem 0',
}));

const ModalBtns = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '2rem',
  height: '4rem',
  width: '100%',
}));

const ModalBtn = styled.button(({ theme, active, activeBtnColor }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '10rem',
  background: active ? activeBtnColor || theme.color.base_black : theme.color.base_white,
  border: active ? '' : `1px solid ${theme.color.base_black}`,
  borderRadius: '0.8rem',
  textAlign: 'center',

  ':hover': {
    opacity: theme.hover.opacity,
  },
}));

const ChoiceModal = ({
  isOpen,
  setIsOpen,
  closeBtnName,
  activeBtnName,
  handleActive,
  handleClose,
  activeBtnColor,
  children,
}) => {
  const theme = useTheme();

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <ModalDesc>
        {children}

        <ModalBtns>
          <ModalBtn onClick={() => handleClose || setIsOpen(false)}>
            <BodyText size="rmd">{closeBtnName || 'Close'}</BodyText>
          </ModalBtn>

          <ModalBtn active activeBtnColor={activeBtnColor} onClick={handleActive}>
            <BodyText size="rmd" color={theme.color.base_white}>
              {activeBtnName || 'Ok'}
            </BodyText>
          </ModalBtn>
        </ModalBtns>
      </ModalDesc>
    </Modal>
  );
};

export default memo(ChoiceModal);
