import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { LOCALSTORAGE_TOKEN } from '@/constants/storageKey';
import { SIGNIN_PATH } from '@/constants/paths';

const useAuthenticated = () => {
  const navigate = useNavigate();

  const isAuthenticated = !!localStorage.getItem(LOCALSTORAGE_TOKEN);

  const logout = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();
    navigate(SIGNIN_PATH);
  }, [navigate]);

  return { isAuthenticated, logout };
};

export default useAuthenticated;
