import React, { useCallback, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import axios from 'axios';

import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { Tooltip } from '@mui/material';

import { ERROR_MESSAGE } from '@/constants/strings';
import { StatusModal } from '@/components/modal';
import { AuthRequiredModal, ReportViewModal } from '@/components/modal/templates';
import { useAuthenticated } from '@/hooks';
import { getLogoSrc } from '@/utils/functions';

const MetaIcons = styled.div(({ isshow }) => ({
  display: isshow ? 'flex' : 'none',
  alignItems: 'center',
  gap: '1.2rem',
  padding: '0.4rem 1rem',
}));

const MetaIconsTop = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1.2rem',
}));

const MetaIcon = styled.img(() => ({
  width: '2.4rem',
  cursor: 'pointer',
}));

const Line = styled.span(({ theme }) => ({
  width: '0.2rem',
  height: '1.2rem',
  background: theme.color.neutral300,
}));

const TooltipCustom = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow placement="top" classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    padding: '1rem 1.4rem',
    background: theme.color.base_white,
    color: theme.color.base_black,
    fontSize: '1.6rem',
  },

  '& .MuiTooltip-arrow': {
    color: theme.color.base_white,
  },
}));

const DocumentManagement = ({ data }) => {
  const theme = useTheme();

  const { isAuthenticated } = useAuthenticated();

  const [openPreventModal, setOpenPreventModal] = useState(false);

  const [downloadModal, setDownloadModal] = useState(false);
  const [downloadCompleted, setDownloadCompleted] = useState(false);

  const [viewModal, setViewModal] = useState(false);

  const [shareModal, setShareModal] = useState(false);

  const preventPropagation = useCallback(
    (e) => {
      if (!isAuthenticated) {
        e.stopPropagation();
        setOpenPreventModal(true);
      } else {
        e.stopPropagation();

        setOpenPreventModal(false);
      }
    },
    [isAuthenticated],
  );

  const handleViewReport = useCallback(() => {
    if (!isAuthenticated) return;

    setViewModal(true);
  }, [isAuthenticated]);

  const handleDownloadReport = useCallback(async () => {
    if (!isAuthenticated) return;

    await axios({
      url: getLogoSrc({
        file_path: data.attach_file_path,
        origin_file_name: data.attach_origin_file_name,
        temp_file_name: data.attach_temp_file_name,
      }),
      method: 'GET',
      responseType: 'blob',
    })
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement('a');

        link.href = downloadUrl;

        link.setAttribute('download', decodeURIComponent(data?.attach_origin_file_name) || 'download.pdf');

        document.body.appendChild(link);

        link.click();

        link.remove();

        setDownloadCompleted(true);
      })

      .catch(() => setDownloadCompleted(false))

      .finally(() => setDownloadModal(true));
  }, [isAuthenticated, data.attach_file_path, data.attach_origin_file_name, data.attach_temp_file_name]);

  const handleShareReport = useCallback(() => {
    // if (!isAuthenticated) return;

    setShareModal(true);
  }, []);

  const currenturl = window.location.href;

  const shareurl = currenturl.includes('detail')
    ? currenturl
    : `${currenturl}${data?.report_key ? `/detail/${data?.report_key}` : ''}`;

  return (
    <div>
      <div onClick={(e) => e.stopPropagation()}>
        <MetaIcons isshow={data?.attach_file_path && data?.attach_file_path !== 'file_path'}>
          <MetaIconsTop onClick={(e) => preventPropagation(e)}>
            <TooltipCustom title="View report" onClick={handleViewReport}>
              <MetaIcon src={theme.image.link} alt="link icon" />
            </TooltipCustom>

            <Line />

            <TooltipCustom title="Download" onClick={handleDownloadReport}>
              <MetaIcon src={theme.image.download_black} alt="download icon" />
            </TooltipCustom>

            <Line />
          </MetaIconsTop>

          <CopyToClipboard text={shareurl} onCopy={handleShareReport}>
            <TooltipCustom title="Share">
              <MetaIcon src={theme.image.share} alt="share icon" />
            </TooltipCustom>
          </CopyToClipboard>
        </MetaIcons>
      </div>

      <div onClick={(e) => e.stopPropagation()}>
        <AuthRequiredModal isopen={openPreventModal} setIsopen={setOpenPreventModal} />

        <StatusModal
          isOpen={downloadModal}
          setIsOpen={setDownloadModal}
          isSuccess={downloadCompleted}
          scsText="Successfully download!"
          flrText={ERROR_MESSAGE.SERVER_ERROR}
        />

        <ReportViewModal
          isOpen={viewModal}
          setIsOpen={setViewModal}
          fileUrl={getLogoSrc({
            file_path: data.attach_file_path,
            origin_file_name: data.attach_origin_file_name,
            temp_file_name: data.attach_temp_file_name,
          })}
        />

        <StatusModal
          isOpen={shareModal}
          setIsOpen={setShareModal}
          isSuccess
          scsText="Successfully copied the link to the clipboard!"
        />
      </div>
    </div>
  );
};

export default DocumentManagement;
