import React from 'react';
import { css, Global } from '@emotion/react';
import AppTheme from '@/theme';

const style = css`
  html {
    font-size: 62.5%;
    box-sizing: border-box;
    line-height: 1.2;
    -webkit-text-size-adjust: none;
    -ms-content-zooming: none;
    -ms-touch-action: pan-x pan-y;
    text-size-adjust: none;
    width: 1280px;
    margin: 0 auto;
  }

  body {
    position: relative;
    box-sizing: border-box;
    font-family: ${AppTheme.fontFamily.primary};
    font-size: 1.6rem;
    padding: 0;
    margin: 0;
    word-break: keep-all;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
  }

  * {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
  }

  a {
    color: inherit;
    font-size: inherit;
    text-decoration: none;
  }

  ul,
  li {
    list-style: none;
  }

  button {
    color: inherit;
    font: inherit;
    cursor: pointer;
    background: inherit;
  }

  body::-webkit-scrollbar {
    width: 8px;
  }

  body::-webkit-scrollbar-thumb {
    height: 10%; /* 스크롤바의 길이 */
    background: #e8e8e8; /* 스크롤바의 색상 */
    background-clip: padding-box;
    border-radius: 120px;
  }

  body::-webkit-scrollbar-track {
    background: transparent; /*스크롤바 뒷 배경 색상*/
  }

  *:not(body)::-webkit-scrollbar {
    width: 20px; /* 스크롤바의 너비 */
  }

  *:not(body)::-webkit-scrollbar-thumb {
    height: 10%; /* 스크롤바의 길이 */
    border-radius: 60px;
    background: #e8e8e8;
    border: 5px solid transparent;
    background-clip: padding-box;
  }

  *:not(body)::-webkit-scrollbar-track {
    background: transparent; /*스크롤바 뒷 배경 색상*/
  }

  * {
    scrollbar-width: thin; /* 또는 auto */
    scrollbar-color: #e8e8e8 transparent; /* 스크롤바 색상 및 트랙 색상 */
  }

  /* 수평 스크롤바 */
  * {
    scrollbar-width: thin; /* 또는 auto */
    scrollbar-color: #e8e8e8 transparent; /* 스크롤바 색상 및 트랙 색상 */
  }

  // 우측 화살표 제거
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .ellipsis-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .ellipsis-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .ellipsis-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .ellipsis-4 {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  // pdf viewer
  .react-pdf__Document {
  }

  .react-pdf__Page {
  }

  .react-pdf__Page__textContent {
  }
`;

const GlobalStyles = () => <Global styles={style} />;

export default GlobalStyles;
