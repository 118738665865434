export const HOME_PATH = '/';

export const SIGNIN_PATH = '/sign-in';

export const SIGNUP_PATH = '/sign-up';

export const SIGNUP_VERIFY_PATH = '/sign-up/verify-code';

export const SEND_CODE_PATH = '/send-code';

export const ACTIVATION_SEND_CODE_PATH = '/activation/send-code';

export const CHANGE_PW_PATH = '/change-pw';

export const MYPAGE_PATH = '/mypage';

export const STARTUP_PATH = '/startup';

export const STARTUP_RGS_PATH = '/startup/register';

export const STARTUP_RGS_HISTORY_PATH = '/startup/add-history';

export const INVESTOR_PATH = '/investor';

export const INSIGHT_PATH = '/insight';

export const NEWS_PATH = '/news';

export const REPORT_PATH = '/report';

export const PARTNERS_PATH = '/partners';

export const HEADER_MENU_PATH = [STARTUP_PATH, INVESTOR_PATH, INSIGHT_PATH, NEWS_PATH, REPORT_PATH, PARTNERS_PATH];
