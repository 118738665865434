import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { jwtDecode } from 'jwt-decode';

import { Divider, Menu, MenuItem, Tooltip } from '@mui/material';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

import { USER_INFO_KEY } from '@/api/queryKey';
import { USER_API_URL } from '@/api/apiUrls';
import { HEADER_MENU_PATH, HOME_PATH, STARTUP_RGS_PATH, SIGNIN_PATH, MYPAGE_PATH } from '@/constants/paths';
import { LOCALSTORAGE_TOKEN } from '@/constants/storageKey';
import { HEADER_MENU, STR_REGISTRATION, STR_SIGNIN } from '@/constants/strings';
import { HEADER_ZINDEX } from '@/constants/zIndex';
import { Image } from '@/components/items';
import { BodyText, LabelText } from '@/components/text';
import { AuthRequiredModal } from '@/components/modal/templates';
import { useAuthenticated, useAxios } from '@/hooks';

const Container = styled.div(() => ({
  position: 'absolute',
  top: 0,

  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '1rem',

  width: '100%',
  height: '8rem',
  padding: '2rem 4rem',
  zIndex: HEADER_ZINDEX,
}));

const LogoWrapper = styled.div(() => ({
  display: 'flex',
  padding: ' 0 0 0.4rem 0',
  cursor: 'pointer',
  minWidth: '18rem',
}));

const MenuWrapper = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '4rem',
}));

const MenuListItem = styled.div(({ theme }) => ({
  cursor: 'pointer',
  ':hover': {
    opacity: theme.hover.opacity,
  },
}));

const InfoWrapper = styled.div(() => ({
  display: 'flex',
  gap: '2rem',
}));

const InfoTextWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '0.4rem',
  cursor: 'pointer',

  '> span': {
    lineHeight: '1.9rem',
  },

  ':hover': {
    opacity: theme.hover.opacity,
  },
}));

const InfoTextIcon = styled.img(() => ({
  width: '2rem',
  height: '2rem',
}));

const TooltipBar = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    '& .MuiTooltip-tooltip': {
      padding: '1rem',
      background: theme.color.light500,
      color: theme.color.base_white,
      fontSize: '1.2rem',
    },

    '& .MuiTooltip-arrow': {
      color: theme.color.light500,
    },
  }),
);

const RequestInfo = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.2rem',
  padding: '0.6rem',
  background: theme.color.light800,
  borderRadius: '0.6rem',
  cursor: 'pointer',
}));

const HeaderPrimary = () => {
  const theme = useTheme();

  const navigate = useNavigate();

  const { isAuthenticated, logout } = useAuthenticated();

  const { axiosInstance } = useAxios();

  const [loginModal, setLoginModal] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickNavigate = useCallback((to) => navigate(to), [navigate]);

  const handleRgsStartup = useCallback(() => {
    if (!isAuthenticated) {
      setLoginModal(true);
      return;
    }

    navigate(STARTUP_RGS_PATH);
  }, [navigate, isAuthenticated]);

  const { data: userInfoName, isSuccess } = useQuery({
    queryKey: [USER_INFO_KEY],
    queryFn: () => {
      const token = JSON.parse(localStorage.getItem(LOCALSTORAGE_TOKEN))?.access_token;

      const userKey = jwtDecode(token).sub;

      return axiosInstance.get(`${USER_API_URL}/${userKey}`);
    },

    select: (data) => data.data.data.user_name,

    enabled: isAuthenticated,
  });

  const handleClickRequest = useCallback(
    () => window.open('https://inventionlab.typeform.com/to/NZcx1af0?typeform-source=www.startup-vn.com', '_blank'),
    [],
  );

  return (
    <>
      <Container>
        <LogoWrapper onClick={() => handleClickNavigate(HOME_PATH)}>
          <Image src={theme.image.logo.default} alt="logo" width="100%" />
        </LogoWrapper>

        <MenuWrapper>
          {HEADER_MENU.map((menu, index) => (
            <MenuListItem key={index} onClick={() => handleClickNavigate(HEADER_MENU_PATH[index])}>
              <LabelText size="md" color={theme.color.base_white}>
                {menu.toUpperCase()}
              </LabelText>
            </MenuListItem>
          ))}
        </MenuWrapper>

        <InfoWrapper>
          <TooltipBar title="Please report the wrong information." arrow placement="top" onClick={handleClickRequest}>
            <RequestInfo>
              <Image src={theme.image.alert_hexagon_white} alt="alert icon" width="2rem" />

              <BodyText size="sbmd" color={theme.color.base_white} className="ellipsis-1">
                Request Info
              </BodyText>
            </RequestInfo>
          </TooltipBar>

          <InfoTextWrapper onClick={handleRgsStartup}>
            <InfoTextIcon src={theme.image.plus} alt="register icon" />

            <LabelText size="md" color={theme.color.base_white} className="ellipsis-1">
              {STR_REGISTRATION.toUpperCase()}
            </LabelText>
          </InfoTextWrapper>

          {isSuccess && isAuthenticated ? (
            <>
              <InfoTextWrapper onClick={handleClick}>
                <InfoTextIcon src={theme.image.userSquare} alt="user info icon" />

                <LabelText size="md" color={theme.color.base_white} className="ellipsis-1">
                  {userInfoName}
                </LabelText>
              </InfoTextWrapper>

              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                disableScrollLock
                sx={{ zIndex: HEADER_ZINDEX }}
                slotProps={{
                  paper: {
                    sx: {
                      width: '10rem',
                      marginTop: '1rem',
                    },
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    navigate(MYPAGE_PATH);
                  }}
                >
                  Mypage
                </MenuItem>

                <Divider />

                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    logout();
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </>
          ) : (
            <InfoTextWrapper onClick={() => handleClickNavigate(SIGNIN_PATH)}>
              <InfoTextIcon src={theme.image.userSquarePlus} alt="signup icon" />

              <LabelText size="md" color={theme.color.base_white} className="ellipsis-1">
                {STR_SIGNIN.toUpperCase()}
              </LabelText>
            </InfoTextWrapper>
          )}
        </InfoWrapper>
      </Container>

      <AuthRequiredModal isopen={loginModal} setIsopen={setLoginModal} />
    </>
  );
};

export default HeaderPrimary;
