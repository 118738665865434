import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClientConfig = {
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchInterval: 10 * 60 * 1000,
      refetchIntervalInBackground: true,
      staleTime: 10 * 60 * 1000,
      gcTime: 10 * 60 * 1000,
      keepPreviousData: true,
    },

    mutations: {
      retry: 0,
    },
  },
};

export const queryClient = new QueryClient(queryClientConfig);

export const QueryProvider = ({ children }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);
