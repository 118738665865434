import React, { Suspense, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';

import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { Router } from './router';
import { AppTheme } from './theme';

import { FallbackGlobal } from '@/components/error';
import { LoadingSpinner } from '@/components/loading';

const App = () => {
  const customMuiTheme = createTheme(AppTheme);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <ThemeProvider theme={customMuiTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} fieldYearPlaceholder="AAAA">
        <QueryErrorResetBoundary>
          {({ reset }) => (
            <ErrorBoundary onReset={reset} fallback={<FallbackGlobal />}>
              {/* <Suspense fallback={<LoadingSpinner />}> */}
              <Router />
              {/* </Suspense> */}
            </ErrorBoundary>
          )}
        </QueryErrorResetBoundary>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
