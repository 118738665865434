import React from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TwitterShareButton,
  TwitterIcon,
  LineShareButton,
  LineIcon,
} from 'react-share';

import styled from '@emotion/styled';
import Modal from '../Modal';

const ModalDesc = styled.div(() => ({
  width: '40vw',
  padding: '2rem 0',
  backgroundColor: 'transparent',
}));

const ShareModal = ({ isOpen, setIsOpen, reportKey }) => {
  const currenturl = window.location.href;

  const shareurl = reportKey ? `${currenturl}/detail/${reportKey}` : currenturl;

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <ModalDesc>
        <FacebookShareButton style={{ marginRight: '20px' }} url={shareurl}>
          <FacebookIcon size={48} round borderRadius={24} />
        </FacebookShareButton>

        <TwitterShareButton style={{ marginRight: '20px' }} url={shareurl}>
          <TwitterIcon size={48} round borderRadius={24} />
        </TwitterShareButton>

        <LineShareButton url={shareurl}>
          <LineIcon size={48} round borderRadius={24} />
        </LineShareButton>
      </ModalDesc>
    </Modal>
  );
};

export default ShareModal;
