import React from 'react';
import styled from '@emotion/styled';

const Text = styled.span(({ theme, fontSize, lineHeight, fontWeight, color }) => ({
  fontSize,
  lineHeight,
  fontWeight,
  fontFamily: theme.fontFamily.primary,
  color: color || theme.color.base_black,
}));

const TitleText = ({ children, size, color, className, onClick }) => {
  const fontStyles = {
    lsm: { fontSize: '1.4rem', lineHeight: '2rem', fontWeight: 400 },
    lmd: { fontSize: '1.8rem', lineHeight: '2.4rem', fontWeight: 400 },
    llg: { fontSize: '2.4rem', lineHeight: '2.8rem', fontWeight: 400 },

    sm: { fontSize: '1.4rem', lineHeight: '2rem', fontWeight: 500 },
    md: { fontSize: '1.8rem', lineHeight: '2.4rem', fontWeight: 500 },
    lg: { fontSize: '2.4rem', lineHeight: '2.8rem', fontWeight: 500 },

    bsm: { fontSize: '1.4rem', lineHeight: '2rem', fontWeight: 600 },
    bmd: { fontSize: '1.8rem', lineHeight: '2.4rem', fontWeight: 600 },
    blg: { fontSize: '2.4rem', lineHeight: '2.8rem', fontWeight: 600 },
  };

  const { fontSize, lineHeight, fontWeight } = fontStyles[size] || fontStyles.md;

  return (
    <Text
      fontSize={fontSize}
      lineHeight={lineHeight}
      fontWeight={fontWeight}
      color={color}
      className={className}
      onClick={onClick}
    >
      {children}
    </Text>
  );
};

export default TitleText;
