import { useEffect, useRef, useState } from 'react';

const useIntersection = (callback) => {
  const [observerTarget, setObserverTarget] = useState(null);

  const observer = useRef(
    new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) return;
        callback();
      },
      { threshold: 0.1, rootMargin: '0px 0px 200px 0px' }, // 옵션 조정
    ),
  );

  useEffect(() => {
    const currentTarget = observerTarget;
    const currentObserver = observer.current;

    if (currentTarget) {
      currentObserver.observe(currentTarget);
    }

    return () => {
      if (currentTarget) {
        currentObserver.unobserve(currentTarget);
      }
    };
  }, [observerTarget]);

  return { setObserverTarget };
};

export default useIntersection;
