import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

import { SIGNIN_PATH } from '@/constants/paths';
import { BodyText, TitleText } from '@/components/text';
import Modal from '../Modal';

const ModalDesc = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '3rem',
  padding: '2rem 4rem',
}));

const ModalBtns = styled.div(() => ({
  display: 'flex',
  gap: '2rem',
  height: '4rem',
}));

const ModalBtn = styled.button(({ theme, active }) => ({
  width: '100%',
  padding: '0 4rem',
  background: active ? theme.color.base_black : theme.color.base_white,
  border: active ? '' : `1px solid ${theme.color.base_black}`,
  borderRadius: '0.8rem',

  ':hover': {
    opacity: theme.hover.opacity,
  },
}));

const AuthRequiredModal = ({ isopen, setIsopen }) => {
  const theme = useTheme();

  const navigate = useNavigate();

  const handleCloseModal = useCallback(() => setIsopen(false), [setIsopen]);

  const handleActiveModal = useCallback(() => navigate(SIGNIN_PATH), [navigate]);

  return (
    <Modal isOpen={isopen} setIsOpen={setIsopen}>
      <ModalDesc>
        <TitleText size="bmd">Please login and use it.</TitleText>

        <ModalBtns>
          <ModalBtn onClick={handleCloseModal}>
            <BodyText size="rmd">Close</BodyText>
          </ModalBtn>

          <ModalBtn active onClick={handleActiveModal}>
            <BodyText size="rmd" color={theme.color.base_white}>
              Login
            </BodyText>
          </ModalBtn>
        </ModalBtns>
      </ModalDesc>
    </Modal>
  );
};

export default AuthRequiredModal;
