import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthenticated } from '@/hooks';

const PrivateRouter = () => {
  const { isAuthenticated } = useAuthenticated();

  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRouter;
