import axios from 'axios';
import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode';

import { TOKEN_REFRESH_URL } from '@/api/apiUrls';
import { LOCALSTORAGE_TOKEN } from '@/constants/storageKey';
import { clearWebStorage } from '@/utils/functions';

const useAxios = () => {
  const tokens = localStorage.getItem(LOCALSTORAGE_TOKEN);

  const parsedToken = tokens && JSON.parse(tokens);

  const axiosInstance = axios.create({
    headers: {
      Authorization: `Bearer ${parsedToken?.access_token}`,
    },
  });

  axiosInstance.interceptors.request.use(
    async (req) => {
      if (!parsedToken) return req;

      const user = jwtDecode(parsedToken.access_token);
      const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

      if (!isExpired) return req;

      const decodedRefreshToken = jwtDecode(parsedToken.refresh_token);
      const isExpiredRefreshToken = dayjs.unix(decodedRefreshToken).diff(dayjs()) < 1;

      if (isExpiredRefreshToken) {
        clearWebStorage();
      } else {
        try {
          const res = await axios.post(TOKEN_REFRESH_URL, {
            refresh_token: parsedToken.refresh_token,
          });

          if (res.status === 200) {
            const newTokens = {
              access_token: res?.data.data,
              refresh_token: parsedToken.refresh_token,
            };

            localStorage.setItem(LOCALSTORAGE_TOKEN, JSON.stringify(newTokens));

            req.headers = {
              Authorization: `Bearer ${newTokens.access_token}`,
            };
          }
        } catch (error) {
          clearWebStorage();
        }
      }

      return req;
    },
    (error) => {
      console.error('instance error', error);
      clearWebStorage();
    },
  );

  return { axiosInstance };
};

export default useAxios;
