import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  ACTIVATION_SEND_CODE_PATH,
  CHANGE_PW_PATH,
  HOME_PATH,
  INSIGHT_PATH,
  INVESTOR_PATH,
  MYPAGE_PATH,
  NEWS_PATH,
  PARTNERS_PATH,
  REPORT_PATH,
  SEND_CODE_PATH,
  SIGNIN_PATH,
  SIGNUP_PATH,
  SIGNUP_VERIFY_PATH,
  STARTUP_PATH,
  STARTUP_RGS_HISTORY_PATH,
  STARTUP_RGS_PATH,
} from '@/constants/paths';
import { FallbackNotFound } from '@/components/error';
import { LoadingSpinner } from '@/components/loading';

import { PrivateRouter, PublicRouter } from '@/router';

// Lazy load components
const Home = lazy(() => import('@/pages/home/Home'));
const Startup = lazy(() => import('@/pages/startup/list/Startup'));
const StartupDetail = lazy(() => import('@/pages/startup/detail/StartupDetail'));
const Investor = lazy(() => import('@/pages/investor/list/Investor'));
const InvestorDetail = lazy(() => import('@/pages/investor/detail/InvestorDetail'));
const Report = lazy(() => import('@/pages/report/list/Report'));
const ReportDetail = lazy(() => import('@/pages/report/detail/ReportDetail'));
const News = lazy(() => import('@/pages/news/list/News'));
const Partners = lazy(() => import('@/pages/partners/list/Partners'));
const Insight = lazy(() => import('@/pages/insight/Insight'));

// Lazy load authentication components
const SignIn = lazy(() => import('@/pages/account/sign-in/SignIn'));
const SignUp = lazy(() => import('@/pages/account/sign-up/SignUp'));
const SignUpVerifyCode = lazy(() => import('@/pages/account/sign-up/SignUpVerifyCode'));
const SendCode = lazy(() => import('@/pages/account/chg-pw/SendCode'));
const ActivationSendCode = lazy(() => import('@/pages/account/sign-up/ActivationSendCode'));
const ChangePw = lazy(() => import('@/pages/account/chg-pw/ChangePw'));

// Lazy load Mypage
const Mypage = lazy(() => import('@/pages/account/mypage/Mypage'));
const StartupRegister = lazy(() => import('@/pages/startup/register/StartupRegister'));
const StartupDealHistory = lazy(() => import('@/pages/startup/register/StartupDealHistory'));

const Router = () => (
  <Suspense fallback={<LoadingSpinner />}>
    <Routes>
      <Route path={HOME_PATH} element={<Home />} />

      <Route path={STARTUP_PATH} element={<Startup />} />
      <Route path={`${STARTUP_PATH}/detail/:id`} element={<StartupDetail />} />

      <Route path={INVESTOR_PATH} element={<Investor />} />
      <Route path={`${INVESTOR_PATH}/detail/:id`} element={<InvestorDetail />} />

      <Route path={REPORT_PATH} element={<Report />} />
      <Route path={`${REPORT_PATH}/detail/:id`} element={<ReportDetail />} />

      <Route path={NEWS_PATH} element={<News />} />
      <Route path={PARTNERS_PATH} element={<Partners />} />
      <Route path={INSIGHT_PATH} element={<Insight />} />

      <Route path="*" element={<FallbackNotFound />} />

      <Route element={<PublicRouter />}>
        <Route path={SIGNIN_PATH} element={<SignIn />} />
        <Route path={SIGNUP_PATH} element={<SignUp />} />
        <Route path={SIGNUP_VERIFY_PATH} element={<SignUpVerifyCode />} />
        <Route path={SEND_CODE_PATH} element={<SendCode />} />
        <Route path={ACTIVATION_SEND_CODE_PATH} element={<ActivationSendCode />} />
        <Route path={CHANGE_PW_PATH} element={<ChangePw />} />
      </Route>

      <Route element={<PrivateRouter />}>
        <Route path={MYPAGE_PATH} element={<Mypage />} />
        <Route path={STARTUP_RGS_PATH} element={<StartupRegister />} />
        <Route path={STARTUP_RGS_HISTORY_PATH} element={<StartupDealHistory />} />
      </Route>
    </Routes>
  </Suspense>
);

export default Router;
