import { color } from '@/theme';

export const PLACEHOLDERS = {
  SIGN_IN_EMAIL: 'name@example.com',
  SIGN_IN_USER_PW: 'Password',
  SIGN_UP_USER_PW: 'Create Password',
  SIGN_UP_CONFIRM: 'Confirm Password',
  SIGN_UP_CLASSIFICATION: 'Select a person',
  SIGN_UP_USERNAME: 'Name',
  SIGN_UP_COMPANY: 'Company Name',
  SIGN_UP_POSITION: 'Your Position',

  VERIFY_CODE: 'Code',

  NEW_PW: 'New Password',
  OLD_PW: 'Old Password',

  RGS_LOGO: 'Company Logo upload',
  RGS_COMPANY_NAME: 'Please Enter Company Name',
  RSG_COMPANY_DESC: 'Tutoring matching service for childeren 2-7 in Vietname',
  RSG_PHONE: 'Please Enter Phone Number',
  RSG_EMAIL: 'Please Enter Contact Email',
  RGS_FACEBOOK: 'Please Enter Facebook Link',
  RGS_WEBSITE: 'Please Enter Website Link',
  RGS_HQ_LOCATION: 'Please Select HQ Location(City, Country)',
  RSG_PARENT_NAME: 'Please Enter Parent Company Name',
  RSG_FOUNDERS: 'Please Enter Founders',
  RSG_LOGO_PROFILE: 'Upload Founder Profile',
  RSG_ENTERPRISE: 'Please Enter the Enterprise Name',
  RSG_BUSINESS_MODEL: 'Please select the Business Model',
  RSG_CHANNEL: 'Please Select Channel',
  RSG_BUSINESS_TYPE: 'Please Select Business Type',

  DEAL_ROUND_NO: 'Select Round No',
  DEAL_STAGE: 'Select Stage',
  DEAL_MAIN_INVESTOR: 'Please Enter only one the Lead Investor',
  DEAL_MAIN_INVESTOR_DROPDOWN: 'Select a registered company',
  DEAL_INVESTOR_LOGO: 'Upload Lead Investor Logo',
  DEAL_LEAD_INVESTORS: 'Please Enter Lead Investors',
  DEAL_OTHER_INVESTORS: 'Please Enter the Other Investors',
  DEAL_MIN_AMOUNT: '1,000,000',
  DEAL_MAX_AMOUNT: '100,000,000',
};

export const ERROR_MESSAGE = {
  EMAIL_REQUIRED: 'Please enter your email.',
  EMAIL_FORMAT: 'Invalid email format.',
  PW_REQUIRED: 'Please enter your password.',
  PW_FORMAT: 'Invalid password format',

  NAME_REQUIRED: 'Please enter your name.',
  CLASSIFICATION_REQUIRED: 'Please select your classification.',
  COMPANY_REQUIRED: 'Please enter your company name.',
  PW_CONFIRM_REQUIRED: 'Please enter your confirm.',
  PW_CONFIRM_INVALID: 'Password do not match.',

  SIGNIN_FAILURE: 'Incorrect email or password.',
  SIGNUP_FAILURE: 'Please try again later.',

  RSG_COMPANY_NAME: 'Please enter the company name',
  RSG_COMPANY_DESC: 'Please enter the description',
  RSG_FOUNDED_DATE: 'Date must be in YYYY-MM format',
  RSG_HQ_LOCATION: 'Please select the HQ Location',
  RSG_HQ_LOCATION_NAME: 'Please enter the HQ Location.',
  RSG_HQ_LOCATION_COORDINATES: 'Please select the HQ Location.',
  RSG_FOUNDER_NAME: 'Please enter Founders',
  RSG_PRIMARY_INDUSTRY: 'Please select the industry',
  RSG_ENTERPRISE_NAME: 'Please enter the enterprise',
  RSG_BUSINESS_MODEL: 'Please select the business model',
  RSG_CHANNEL: 'Please select the channel',
  RSG_BUSINESS_TYPE: 'Please select the business type',

  DEAL_ROUND_NO: 'Investment Round is required.',
  DEAL_STAGE: 'Investment Stage is required.',
  DEAL_MAIN_INVESTOR: 'Required Lead Investor',
  DEAL_DATE: 'Please select Investment Closing Date',
  DEAL_AMOUNT: 'Please input Investment Amount',
  DEAL_REQUIRED: 'Please enter your deal history\nIf there is nothing to enter, click the Skip button',

  SERVER_ERROR: 'Failed. Please contact the administrator.',
};

export const HEADER_MENU = ['startup', 'investor', 'insight', 'news', 'report', 'partners'];

export const STR_REGISTRATION = 'reg';

export const STR_SIGNIN = 'sign in';

export const STR_SIGNUP = 'sign up';

export const STR_FORGET_PW = 'Forgot your Password?';

export const STR_VIEW_MORE = 'View More';

export const ALL_STAGE_COLORS = [
  color.light50,
  color.light100,
  color.light200,
  color.light300,
  color.light400,
  color.light500,
  color.light600,
  color.light700,
  color.light800,
  color.light900,
  color.light950,
  color.light1000,
];

export const EARLY_STAGE_COLORS = [color.light50, color.light100, color.light200, color.light300, color.light400];

export const MIDDLE_STAGE_COLORS = [color.light500, color.light600, color.light700, color.light800];

export const LATE_STAGE_COLORS = [color.light900, color.light950, color.light1000];
