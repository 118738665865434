import React from 'react';
import { useNavigate } from 'react-router-dom';

import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

import { HOME_PATH } from '@/constants/paths';
import { HeaderSecondary } from '../_shared';
import { BodyText, DisplayText, TitleText } from '../text';

const Container = styled.div(() => ({
  position: 'relative',
  top: '20rem',
  paddingTop: '8rem',
}));

const ContentWrapper = styled.div(({ theme }) => ({
  position: 'absolute',
  top: '20%',
  left: '50%',
  transform: 'translate(-50%)',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4rem',

  width: '100rem',
  padding: '4rem',

  background: theme.color.base_white,
  borderRadius: '1rem',
  // boxShadow: theme.shadow.primary,
}));

const TextWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  gap: '1rem',
}));

const Button = styled.div(({ theme }) => ({
  padding: '1rem 2rem',
  background: theme.color.neutral400,
  borderRadius: '0.8rem',
  cursor: 'pointer',

  ':hover': {
    opacity: theme.hover.opacity,
  },
}));

const FallbackNotFound = () => {
  const theme = useTheme();

  const navigate = useNavigate();

  return (
    <>
      <HeaderSecondary />

      <Container>
        <ContentWrapper>
          <TextWrapper>
            <DisplayText size="sm">This Page Could Not Be Found</DisplayText>

            <TitleText size="lg">SORRY BUT THE PAGE YOU ARE LOOKING FOR DOES NOT EXIST</TitleText>
          </TextWrapper>

          <Button type="button" onClick={() => navigate(HOME_PATH, { replace: true })}>
            <BodyText color={theme.color.base_white}>GO TO HOMEPAGE</BodyText>
          </Button>
        </ContentWrapper>
      </Container>
    </>
  );
};

export default FallbackNotFound;
