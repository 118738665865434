import React from 'react';
import styled from '@emotion/styled';

const Text = styled.span(({ theme, fontSize, lineHeight, fontWeight, color }) => ({
  fontSize,
  lineHeight,
  fontWeight,
  fontFamily: theme.fontFamily.primary,
  color: color || theme.color.base_black,
}));

const DisplayText = ({ children, size, color, className }) => {
  const fontStyles = {
    sm: { fontSize: '3.6rem', lineHeight: '4.4rem', fontWeight: 800 },
    md: { fontSize: '5rem', lineHeight: '5.8rem', fontWeight: 800 },
    lg: { fontSize: '7rem', lineHeight: '7.8rem', fontWeight: 800 },

    bsm: { fontSize: '3.6rem', lineHeight: '4.4rem', fontWeight: 900 },
    bmd: { fontSize: '5rem', lineHeight: '5.8rem', fontWeight: 900 },
    blg: { fontSize: '7rem', lineHeight: '7.8rem', fontWeight: 900 },
  };

  const { fontSize, lineHeight, fontWeight } = fontStyles[size] || fontStyles.md;

  return (
    <Text fontSize={fontSize} lineHeight={lineHeight} fontWeight={fontWeight} color={color} className={className}>
      {children}
    </Text>
  );
};

export default DisplayText;
