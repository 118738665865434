export const image = {
  // icons
  // logo: require('@/assets/icons/logo.png'),
  logo: require('@/assets/icons/logo.svg'),
  logo_red: require('@/assets/icons/logo-red.png'),
  logo_default: require('@/assets/icons/logo-default.png'),
  alert_hexagon: require('@/assets/icons/alert-hexagon.png'),
  alert_hexagon_white: require('@/assets/icons/alert-hexagon-white.png'),
  buildingIcon: require('@/assets/icons/building.png'),
  buildingIcon_5: require('@/assets/icons/building-5.png'),
  calendar: require('@/assets/icons/calendar.png'),
  calendar_black: require('@/assets/icons/calendar-black.png'),
  eyeOff: require('@/assets/icons/eye-off.png'),
  eye: require('@/assets/icons/eye.png'),
  searchIcon: require('@/assets/icons/search-lg.png'),
  upload: require('@/assets/icons/upload.png'),
  checkOn: require('@/assets/icons/checkbox-on.png'),
  checkOff: require('@/assets/icons/checkbox-off.png'),
  symbol: require('@/assets/icons/symbol.png'),
  symbol_white: require('@/assets/icons/symbol-white.png'),
  lock_key: require('@/assets/icons/lock-key.png'),
  check_modal: require('@/assets/icons/check-modal.png'),
  clock: require('@/assets/icons/clock.png'),
  clock_gray: require('@/assets/icons/clock-gray.png'),
  download: require('@/assets/icons/download.png'),
  download_black: require('@/assets/icons/download-black.png'),
  trash: require('@/assets/icons/trash.png'),
  funnel: require('@/assets/icons/funnel.png'),
  marker_pin: require('@/assets/icons/marker-pin.png'),
  hand: require('@/assets/icons/hand.png'),
  globe: require('@/assets/icons/globe.png'),
  mail: require('@/assets/icons/mail.png'),
  phone: require('@/assets/icons/phone.png'),
  facebook: require('@/assets/icons/facebook.png'),
  twitter: require('@/assets/icons/twitter.png'),
  linkedin: require('@/assets/icons/linkedin.png'),
  chart_bar: require('@/assets/icons/chart-bar.png'),
  link: require('@/assets/icons/link.png'),
  share: require('@/assets/icons/share.png'),
  chevron_up: require('@/assets/icons/chevron-up.png'),
  chevron_down: require('@/assets/icons/chevron-down.png'),
  x_close: require('@/assets/icons/x-close.png'),
  x_circle: require('@/assets/icons/x-circle.png'),
  info_circle: require('@/assets/icons/info-circle.png'),
  plus: require('@/assets/icons/plus.png'),
  plus_black: require('@/assets/icons/plus-black.png'),
  user_default: require('@/assets/icons/user-default.png'),
  userSquare: require('@/assets/icons/user-square.png'),
  userSquarePlus: require('@/assets/icons/user-square-plus.png'),
  userSquareBlack: require('@/assets/icons/user-square-black.png'),
  user_square_gray: require('@/assets/icons/user-square-gray.png'),
  arrow_left: require('@/assets/icons/arrow-left.png'),
  arrow_right: require('@/assets/icons/arrow-right.png'),
  success_rgs: require('@/assets/icons/success-rgs.png'),

  company_logo_1: require('@/assets/icons/company-logo-1.png'),
  company_logo_2: require('@/assets/icons/company-logo-2.png'),
  company_logo_3: require('@/assets/icons/company-logo-3.png'),

  // images
  main: require('@/assets/images/main.png'),
  mainTop: require('@/assets/images/main-top.png'),
  mainFull: require('@/assets/images/main-full.png'),
  startupBanner: require('@/assets/images/startup-banner.png'),
  investor_banner: require('@/assets/images/investor-banner.png'),
  insight_banner: require('@/assets/images/insight-banner.png'),
  history_blur: require('@/assets/images/history-blur.png'),
  news_banner: require('@/assets/images/news-banner.png'),
  report_banner: require('@/assets/images/report-banner.png'),
  partners_banner: require('@/assets/images/partners-banner.png'),
  news_default: require('@/assets/images/news-default.png'),
};

export const color = {
  base_white: '#ffffff',
  base_light_white: '#FDFDFD',
  base_black: '#222222',
  base_light_gray: '#FAFAFA',
  base_blue: '#3677ff',
  bg_black: '#02122C',

  light50: '#faeff0',
  light100: '#f1cecf',
  light200: '#e39d9f',
  light300: '#d56b70',
  light400: '#fa3939',
  light500: '#c73a40',
  light600: '#b90910',
  light700: '#94070d',
  light800: '#a11313',
  light900: '#6f050a',
  light950: '#4a0406',
  light1000: '#250203',

  neutral50: '#F9FAFB',
  neutral100: '#F2F4F7',
  neutral200: '#E4E7EC',
  neutral300: '#D0D5DD',
  neutral400: '#98A2B3',
  neutral500: '#667085',
  neutral600: '#475467',
  neutral700: '#344054',
  neutral800: '#1D2939',
  neutral900: '#101828',
};

export const AppTheme = {
  image,

  color,

  hover: {
    opacity: 0.8,
  },

  gradient: {
    primary: 'linear-gradient(to top, #FA4545, #FFE2DC)',
    bg_neutral: 'linear-gradient(#F9FAFB, #FFF8F8)',
    bg_medium: 'linear-gradient(#F9FAFB, #FFF5F2)',
    bg_overlay: 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))',
    bg_chart: 'linear-gradient(to bottom, #FFFFFF, #FFF1F1)',
    blur_bottom: 'linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);',
  },

  fontFamily: {
    primary: 'Pretendard, sans-serif',
  },

  fontWeight: {},

  shadow: {
    primary: '0px 0px 4px 0px rgba(0, 0, 0, 0.2)',
  },

  filter: {
    brightness: 'brightness(0.9)',
  },

  // Custom MUI
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: '1.6rem',
          fontFamily: theme.fontFamily.primary,
          color: theme.color.base_black,
          verticalAlign: 'middle',

          '& .MuiOutlinedInput-input': {},

          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '0.6rem',
          },

          input: {
            '&::placeholder': {
              color: theme.color.neutral500,
            },
          },
        }),
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-focused': {
            color: color.base_black,

            '& .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${theme.color.base_black}`,
            },
          },
        }),
      },
    },

    MuiPickersLayout: {
      styleOverrides: {
        root: {
          aspectRatio: 1,
        },
      },
    },

    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          // margin: '1rem 0',
        },
        label: ({ theme }) => ({
          color: theme.color.base_black,
          fontSize: '2rem',
        }),
        switchViewButton: {
          padding: 0,
        },
        switchViewIcon: {
          fontSize: '2.4rem',
        },
      },
    },

    MuiYearCalendar: {
      styleOverrides: {
        root: () => ({
          width: '100%',
          padding: 0,
          overflow: 'auto',
        }),
      },
    },

    MuiPickersYear: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.color.base_black,
        }),
      },
    },

    MuiDayCalendar: {
      styleOverrides: {
        weekDayLabel: {
          fontWeight: 700,
          color: '#222222',
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          background: theme.color.base_white,
          color: theme.color.base_black,
          fontSize: '1.4rem',

          '&.Mui-selected': {
            background: theme.color.base_white,
            color: theme.color.base_black,
            fontWeight: 600,

            '&:focus': {
              background: theme.color.base_white,
            },
            '&:hover': {
              background: theme.color.neutral50,
            },
          },
        }),
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.color.neutral500,
          borderRadius: '0.6rem',
        }),
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          '& .MuiSvgIcon-root': {},
          '&.Mui-checked': {},
        },
      },
    },
  },
};

export default AppTheme;
