import React from 'react';
import styled from '@emotion/styled';
import { image } from '@/theme';

import Modal from './Modal';
import { BodyText } from '../text';
import { Image } from '../items';

const ModalDesc = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '2rem',

  width: '40rem',
  padding: '2rem 0',
  whiteSpace: 'pre-line',
}));

const StatusModal = ({ isOpen, setIsOpen, handleCloseModal, isSuccess, scsText, flrText }) => {
  const iconSrc = isSuccess ? image.check_modal : image.x_circle;
  const iconAlt = isSuccess ? 'success icon' : 'failure icon';
  const text = isSuccess ? scsText : flrText;

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} handleCloseModal={handleCloseModal}>
      <ModalDesc>
        <Image src={iconSrc} alt={iconAlt} />

        <BodyText size="blg">{text}</BodyText>
      </ModalDesc>
    </Modal>
  );
};

export default StatusModal;
