import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import App from './App';
import GlobalStyles from './assets/styles/GlobalStyles';
import { QueryProvider } from './api/queryProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <QueryProvider>
      <GlobalStyles />

      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />

      <App />
    </QueryProvider>
  </BrowserRouter>,
);
