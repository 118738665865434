export const TOKEN_REFRESH_URL = '/api/v1/user/token-refresh';

export const GET_CODE_API_URL = '/api/v1/code';

export const RESET_PW_SEND_CODE_API_URL = '/api/v1/user/reset-pw/email';
export const RESET_PW_SEND_VERIFY_API_URL = '/api/v1/user/reset-pw/verify';
export const RESET_PW_API_URL = '/api/v1/user/reset-pw';

export const CHANGE_USERINFO_API_URL = '/api/v1/user';
export const CHANGE_USERPW_API_URL = '/api/v1/user/pw';

export const ACTIVATION_API_URL = '/api/v1/user/activation';
export const SIGN_UP_API_URL = '/api/v1/user/sign-up';
export const SIGN_IN_API_URL = '/api/v1/user/sign-in';

export const USER_API_URL = '/api/v1/user';

export const FILE_UPLOAD_API_URL = '/api/v1/file/upload';
export const FILE_DOWNLOAD_API_URL = '/api/v1/file/download';

export const BANNER_API_URL = '/api/v1/banner';

export const STARTUP_API_URL = '/api/v1/startup';
export const STARTUP_RGS_API_URL = '/api/v1/startup';
export const STARTUP_RGS_HISTORY_API_URL = '/api/v1/startup/deal-history';
export const GET_STARTUP_LIST_URL = '/api/v1/startup/list';
export const STARTUP_DEAL_HISTORY_URL = '/api/v1/startup/deal-history';

export const INVESTOR_API_URL = '/api/v1/investor';
export const GET_INVESTOR_LIST_URL = '/api/v1/investor/list';
export const INVESTOR_DEAL_HISTORY_URL = '/api/v1/investor/invest-history';

export const GET_NEWS_LIST_URL = '/api/v1/news';

export const GET_PARTNERS_LIST_URL = '/api/v1/partner';

export const GET_REPORT_LIST_URL = '/api/v1/report';

export const STATISTICS_STAGE_API_URL = '/api/v1/statistics/invest-amount-each-stage';
export const STATISTICS_FUNDING_API_URL = '/api/v1/statistics/funding-cnt';
export const STATISTICS_INVEST_API_URL = '/api/v1/statistics/invest-amount';
export const STATISTICS_RANKING_CNT_API_URL = '/api/v1/statistics/invest-cnt-ranking';
export const STATISTICS_RANKING_AMOUNT_API_URL = '/api/v1/statistics/invest-amount-ranking';
