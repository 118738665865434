import React, { useCallback, useEffect } from 'react';
import styled from '@emotion/styled';

import { image } from '@/theme';

import { MODAL_ZINDEX } from '@/constants/zIndex';
import { Image } from '../items';
import ModalPortal from './ModalPortal';

const ModalContainer = styled.div(() => ({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: MODAL_ZINDEX,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100vw',
  height: '100vh',
}));

const Overlay = styled.div(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: MODAL_ZINDEX,
  width: '100%',
  height: 'calc(100% + 5%)',
  background: 'rgba(0, 0, 0, 0.4)',
}));

const ModalContent = styled.div(({ theme, bgColor }) => ({
  position: 'relative',
  zIndex: MODAL_ZINDEX,
  padding: '2rem',
  background: bgColor ?? theme.color.base_white,
  borderRadius: '0.8rem',
  textAlign: 'center',
}));

const CloseIconWrapper = styled.div(() => ({
  position: 'absolute',
  top: 10,
  right: 10,
  cursor: 'pointer',
}));

const Modal = ({ isOpen, setIsOpen, handleCloseModal, children, bgColor }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.cssText = `
      // overflow-y: hidden;
      width: 100%;`;
    }

    return () => {
      document.body.style.cssText = '';
    };
  }, [isOpen]);

  const closeModal = useCallback(() => setIsOpen(false), [setIsOpen]);

  return isOpen ? (
    <ModalPortal>
      <ModalContainer>
        <Overlay onClick={handleCloseModal || closeModal} />

        <ModalContent bgColor={bgColor}>
          <CloseIconWrapper onClick={handleCloseModal || closeModal}>
            <Image src={image.x_close} width="2rem" height="2rem" />
          </CloseIconWrapper>

          {children}
        </ModalContent>
      </ModalContainer>
    </ModalPortal>
  ) : null;
};

export default Modal;
