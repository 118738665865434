import React, { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import styled from '@emotion/styled';
import { Pagination } from '@mui/material';

const Container = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '4rem 0',
}));

const StyledPagination = styled(Pagination)(({ theme }) => ({
  '.MuiPagination-ul': {
    gap: 0,
    width: '100%',
    height: '100%',

    '>:first-of-type': {
      paddingRight: '8rem',
    },

    '>:last-child': {
      paddingLeft: '8rem',
    },
  },

  '.MuiPaginationItem-root': {
    color: theme.color.neutral400,
    fontSize: '1.4rem',
    border: 'none',
    borderRadius: '0.4rem',
    cursor: 'pointer',
  },

  '& .MuiPaginationItem-circular': {
    width: '4rem',
    height: '4rem',
  },

  '& .MuiPaginationItem-ellipsis': {
    height: '100%',
  },

  '& .MuiPaginationItem-icon': {
    width: '2rem',
    height: '2rem',
    background: theme.color.base_light_white,
    color: theme.color.neutral400,
    border: 'none',
    borderRadius: '0.4rem',
    cursor: 'pointer',
  },

  '.Mui-selected': {
    fontWeight: 500,
    color: theme.color.base_black,
    background: theme.color.neutral100,
    border: 'none',

    backgroundPosition: '100% 0',
    mozTransition: 'all .1s ease-out',
    transition: 'all .1s ease-out',
  },
}));

const BasicPagination = ({ page, totalPage, scrollRef }) => {
  const [params, setParams] = useSearchParams();

  const changePageHandler = useCallback(
    (_, value) => {
      if (scrollRef?.current) {
        scrollRef.current.scrollIntoView({ behavior: 'smooth' });

        scrollRef.current.scrollTo({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }

      const newParams = new URLSearchParams(params);
      newParams.set('page', value);
      setParams(newParams);
    },
    [params, scrollRef, setParams],
  );

  return (
    <Container>
      <StyledPagination page={page} count={totalPage} onChange={changePageHandler} variant="outlined" size="small" />
    </Container>
  );
};

export default BasicPagination;
