import React from 'react';
import styled from '@emotion/styled';

const Text = styled.span(({ theme, fontSize, lineHeight, color }) => ({
  fontSize,
  lineHeight,
  fontWeight: 600,
  fontFamily: theme.fontFamily.primary,
  color: color || theme.color.base_black,
}));

const LabelText = ({ children, size, color, className }) => {
  const fontStyles = {
    sm: { fontSize: '1.2rem', lineHeight: '1.6rem' },
    md: { fontSize: '1.6rem', lineHeight: '1.6rem' },
    lg: { fontSize: '1.8rem', lineHeight: '2.7rem' },
  };

  const { fontSize, lineHeight } = fontStyles[size] || fontStyles.md;

  return (
    <Text fontSize={fontSize} lineHeight={lineHeight} color={color} className={className}>
      {children}
    </Text>
  );
};

export default LabelText;
