import React from 'react';
import styled from '@emotion/styled';

const Text = styled.span(({ theme, fontSize, lineHeight, fontWeight, color }) => ({
  fontSize,
  lineHeight,
  fontWeight,
  fontFamily: theme.fontFamily.primary,
  color: color || theme.color.base_black,
}));

const HeadText = ({ children, size, color, className }) => {
  const fontStyles = {
    lsm: { fontSize: '2.4rem', lineHeight: '3.2rem', fontWeight: 400 },
    lmd: { fontSize: '2.8rem', lineHeight: '3.6rem', fontWeight: 400 },
    llg: { fontSize: '3.2rem', lineHeight: '4rem', fontWeight: 400 },

    sm: { fontSize: '2.4rem', lineHeight: '3.2rem', fontWeight: 700 },
    md: { fontSize: '2.8rem', lineHeight: '3.6rem', fontWeight: 700 },
    lg: { fontSize: '3.2rem', lineHeight: '4rem', fontWeight: 700 },

    bsm: { fontSize: '2.4rem', lineHeight: '3.2rem', fontWeight: 800 },
    bmd: { fontSize: '2.8rem', lineHeight: '3.6rem', fontWeight: 800 },
    blg: { fontSize: '3.2rem', lineHeight: '4rem', fontWeight: 800 },
  };

  const { fontSize, lineHeight, fontWeight } = fontStyles[size] || fontStyles.md;

  return (
    <Text fontSize={fontSize} lineHeight={lineHeight} fontWeight={fontWeight} color={color} className={className}>
      {children}
    </Text>
  );
};

export default HeadText;
