import React from 'react';
import styled from '@emotion/styled';

const StyledImg = styled.img(({ imgWidth, imgHeight, objectFit }) => ({
  display: 'block',
  minWidth: imgWidth || '2.4rem',
  minHeight: imgHeight || '2.4rem',
  width: imgWidth || '2.4rem',
  height: imgHeight || '2.4rem',
  objectFit,
}));

const Image = ({ src, alt, onClick, width, height = width, objectFit, ...props }) => (
  <StyledImg
    src={src}
    alt={alt}
    imgWidth={width}
    imgHeight={height}
    objectFit={objectFit}
    onClick={onClick}
    {...props}
  />
);

export default Image;
