import React from 'react';
import styled from '@emotion/styled';

const Text = styled.span(({ theme, fontSize, lineHeight, color, underline, fontWeight }) => ({
  fontSize,
  lineHeight,
  fontWeight,
  fontFamily: theme.fontFamily.primary,
  color: color || theme.color.base_black,
  textDecoration: underline ? 'underline' : '',
  cursor: underline ? 'pointer' : '',
}));

const BodyText = ({ children, size, color, className, underline, onClick }) => {
  const fontStyles = {
    sm: { fontSize: '1.2rem', lineHeight: '1.6rem', fontWeight: 400 },
    md: { fontSize: '1.4rem', lineHeight: '1.5rem', fontWeight: 400 },
    lg: { fontSize: '1.6rem', lineHeight: '2.4rem', fontWeight: 400 },

    rsm: { fontSize: '1.2rem', lineHeight: '1.6rem', fontWeight: 500 },
    rmd: { fontSize: '1.4rem', lineHeight: '1.4rem', fontWeight: 500 },
    rlg: { fontSize: '1.6rem', lineHeight: '2.4rem', fontWeight: 500 },

    sbsm: { fontSize: '1.2rem', lineHeight: '1.6rem', fontWeight: 600 },
    sbmd: { fontSize: '1.4rem', lineHeight: '1.5rem', fontWeight: 600 },
    sblg: { fontSize: '1.6rem', lineHeight: '2.4rem', fontWeight: 600 },

    bsm: { fontSize: '1.2rem', lineHeight: '1.6rem', fontWeight: 700 },
    bmd: { fontSize: '1.4rem', lineHeight: '1.4rem', fontWeight: 700 },
    blg: { fontSize: '1.6rem', lineHeight: '2.4rem', fontWeight: 700 },
  };

  const { fontSize, lineHeight, fontWeight } = fontStyles[size] || fontStyles.md;

  return (
    <Text
      fontSize={fontSize}
      lineHeight={lineHeight}
      fontWeight={fontWeight}
      color={color}
      underline={underline}
      className={className}
      onClick={onClick}
    >
      {children}
    </Text>
  );
};

export default BodyText;
