import React, { useRef } from 'react';
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';

import styled from '@emotion/styled';

import { BodyText } from '../text';

const Container = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  width: '100%',
  height: '100%',
}));

const mapStyle = {
  width: '100%',
  height: '100%',
  minHeight: '30rem',
};

const ChipWrapper = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
}));

const LocationChip = styled.div(({ theme }) => ({
  padding: '0.3rem 1rem',
  marginRight: '1rem',
  background: theme.color.neutral50,
  borderRadius: '10rem',
  textAlign: 'center',
}));

const libraries = ['places'];

const LocationMap = ({
  location = { address: 'Vietnam', country: 'Vietnam', lat: 14.058324, lng: 108.277199, zoom: 5 },
}) => {
  const mapRef = useRef();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
    language: 'en',
  });

  const onLoadMap = (map) => {
    mapRef.current = map;
  };

  return (
    isLoaded && (
      <Container>
        <GoogleMap
          mapContainerStyle={mapStyle}
          center={{ lat: location?.lat, lng: location?.lng }}
          onLoad={onLoadMap}
          zoom={location?.zoom}
        >
          <MarkerF position={{ lat: location?.lat, lng: location?.lng }} />
        </GoogleMap>

        <ChipWrapper>
          <BodyText size="rlg">HQ Location</BodyText>

          {location?.address && (
            <LocationChip>
              <BodyText className="ellipsis-1">{location?.address}</BodyText>
            </LocationChip>
          )}
        </ChipWrapper>
      </Container>
    )
  );
};

export default LocationMap;
