import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import styled from '@emotion/styled';
import { LoadingIcon } from '@/components/loading/LoadingSpinner';
import Modal from '../Modal';

import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const ModalDesc = styled.div(({ width }) => ({
  width,
  padding: '2rem 0',
  backgroundColor: 'transparent',
}));

const ReportViewModal = ({ isOpen, setIsOpen, fileUrl }) => {
  const swiperRef = useRef(null);

  const [numPages, setNumPages] = useState();
  const [, setPageNumber] = useState(1);
  const [pdfWidth, setPdfWidth] = useState('80vw');

  const maxViewPages = numPages > 5 ? 5 : numPages;

  const onDocumentLoadSuccess = useCallback((pdf) => {
    setNumPages(pdf.numPages);

    pdf.getPage(1).then((page) => {
      const viewport = page.getViewport({ scale: 1 });
      const pageWidth = viewport.width;
      setPdfWidth(`${pageWidth}px`);
    });
  }, []);

  const handleSlideChange = useCallback(() => {
    const { swiper } = swiperRef.current;
    const currentIndex = swiper.activeIndex;
    setPageNumber(currentIndex + 1);
  }, []);

  useEffect(() => {
    if (!isOpen) setPageNumber(1);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} bgColor="transparent">
      <ModalDesc width={pdfWidth}>
        <Document file={fileUrl} loading={<LoadingIcon />} onLoadSuccess={onDocumentLoadSuccess}>
          <Swiper
            ref={swiperRef}
            modules={[Pagination]}
            pagination={{ clickable: true }}
            onSlideChange={handleSlideChange}
            style={{ width: pdfWidth }}
          >
            {Array(maxViewPages)
              .fill()
              .map((_, idx) => (
                <SwiperSlide key={idx}>
                  <Page pageNumber={idx + 1} scale={1} height={700} width={parseInt(pdfWidth, 10)} />
                </SwiperSlide>
              ))}
          </Swiper>
        </Document>
      </ModalDesc>
    </Modal>
  );
};

export default ReportViewModal;
