export const USER_TYPE_CODE = { key: 'userTypeCodeKey', groupKey: 1 };
export const HQ_LOCATION_CODE = { key: 'HQLocationCodeKey', groupKey: 2 };
export const INDUSTRY_CODE = { key: 'industryCodeKey', groupKey: 3 };
export const BUSINESS_MODEL_CODE = { key: 'businessModelCodeKey', groupKey: 4 };
export const CHANNEL_CODE = { key: 'channelCodeKey', groupKey: 5 };
export const BUSINESS_TYPE_CODE = { key: 'businessTypeCodeKey', groupKey: 6 };
export const INVESTMENT_STAGE_CODE = { key: 'investmentStageCodeKey', groupKey: 7 };
export const INVESTMENT_REGION_CODE = { key: 'investmentRegionCodeKey', groupKey: 8 };
export const HEADQUARTER_CODE = { key: 'headquarterCodeKey', groupKey: 9 };
export const COMPANY_STAGE_CODE = { key: 'CompanyStageCodeKey', groupKey: 10 };
export const COUNTRY_CODE = { key: 'CountryCodeKey', groupKey: 11 };

export const USER_INFO_KEY = 'userInfo';

export const BANNER_LIST_KEY = 'bannerList';

export const STARTUP_LIST_KEY = 'startupList';
export const STARTUP_DEAL_HISTORY_KEY = 'startupDealHistory';

export const INVESTOR_LIST_KEY = 'investList';
export const INVESTOR_DEAL_HISTORY_KEY = 'investorDealHistory';

export const NEWS_LIST_KEY = 'newsList';

export const PARTNERS_LIST_KEY = 'partnersList';

export const REPORT_LIST_KEY = 'reportList';
export const REPORT_LIST_OTHER = 'reportListOther';
export const REPORT_LIST_ALL_KEY = 'reportListAll';

export const STATISTICS_STAGE_KEY = 'statisticsStage';
export const STATISTICS_FUNDING_CNT_KEY = 'statisticsFundingCntKey';
export const STATISTICS_INVEST_DATE_KEY = 'statisticsInvestDateKey';
export const STATISTICS_RANKING_AMOUNT_KEY = 'statisticsRankingAmountKey';
export const STATISTICS_RANKING_CNT_KEY = 'statisticsRankingCntKey';
